import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isClientSide } from "@ecp-redux/api/api";
import { useEffect, useState } from "react";

type ScrollIntoElementHook = (
  ref: React.RefObject<HTMLDivElement>
) => () => void;

const useScrollIntoElement: ScrollIntoElementHook = (ref) => {
  const {
    stylePages: {
      pages: {
        headerFooter: { stickyHeader },
      },
    },
  } = useTheme() as IThemeState;

  const [totalStickyHeaderHeight, setTotalStickyHeaderHeight] = useState(0);

  useEffect(() => {
    const stickyElements = isClientSide()
      ? Array.from(document.querySelectorAll("header > *")).filter((el) => {
          return window.getComputedStyle(el).position === "sticky";
        })
      : [];

    const totalHeight = stickyElements.reduce((sum, element) => {
      return sum + element.getBoundingClientRect().height;
    }, 0);

    setTotalStickyHeaderHeight(totalHeight);
  }, []);

  const scrollIntoElement: () => void = () => {
    const element = ref.current;

    if (element) {
      const offset = stickyHeader ? totalStickyHeaderHeight + 20 : 20;

      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Dodatkowe przesunięcie po scrollIntoView
      window.scrollBy({
        top: -offset,
        behavior: "smooth",
      });
    }
  };

  return scrollIntoElement;
};

export default useScrollIntoElement;
