import { useMemo } from "react";
import debounce from "lodash.debounce";
import ChevronRightIcon from "../../ChevronRightIcon/ChevronRightIcon";
import { PaginationWrapper } from "../Pagination.styled";
import { useRouter } from "next/router";

interface PaginationWithInputProps {
  currentPage: number;
  pageCount: number;
  customStyles?: string;
  scrollIntoElement: () => void;
}

const PaginationWithInput: React.FC<PaginationWithInputProps> = ({
  currentPage,
  pageCount,
  customStyles,
  scrollIntoElement,
}) => {
  const router = useRouter();
  const lastPageIndex = pageCount;
  const hasNextPage = currentPage < lastPageIndex;

  const inputChange = (index: string) => {
    if (
      index.match(new RegExp("^[0-9]+$")) &&
      +index <= lastPageIndex &&
      +index > 0
    ) {
      handlePageSelection(+index);
    }
    scrollIntoElement();
  };

  const handlePageSelection = useMemo(
    () =>
      debounce((index: number) => {
        router.push(
          {
            pathname: router.pathname,
            query: { ...router.query, page: index.toString() },
          },
          undefined,
          { shallow: true }
        );
      }, 500),
    [router]
  );

  return (
    <PaginationWrapper
      customStyles={customStyles}
      className="pagination-container"
    >
      {currentPage > 1 && (
        <button
          className="pagination-container__chevron-icon pagination-container__chevron-icon--prev"
          data-testid="btn-prev"
          onClick={() => inputChange(String(currentPage - 1))}
        >
          <ChevronRightIcon />
        </button>
      )}
      <input
        className="pagination-container__input"
        value={currentPage}
        max={lastPageIndex}
        min={1}
        onChange={(e) => inputChange(e.target.value)}
      />
      <p className="pagination-container__out-of">z</p>
      <p className="pagination-container__total-count">{lastPageIndex}</p>
      {hasNextPage && (
        <button
          className="pagination-container__chevron-icon--next"
          data-testid="btn-next"
          onClick={() => inputChange(String(currentPage + 1))}
        >
          <ChevronRightIcon />
        </button>
      )}
    </PaginationWrapper>
  );
};

export default PaginationWithInput;
