import { useRef } from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import { ArticleListBoxType } from "@ecp-redux/dto/article.type";
import { StyledBoxArticleList } from "../../settingsPatterns/boxes/BoxArticleList/StyledBoxArticleList";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useFetchArticleList } from "./BoxArticleList.methods";
import { IBoxArticleListProps } from "./BoxArticleList.types";
import BoxArticleListGrid from "./views/BoxArticleListGrid/BoxArticleListGrid";
import BoxArticleListSlider from "./views/BoxArticleListSlider";
import {
  BoxArticleListContentScheme,
  BoxArticleListContentSettingsScheme,
  BoxArticleListDisplaySettingsScheme,
} from "./BoxArticleList.scheme";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";

const BoxArticleList: React.FC<IBoxArticleListProps> = (props) => {
  const displaySettings = BoxArticleListDisplaySettingsScheme.parse(
    props.displaySettings
  );
  const contentSettings = BoxArticleListContentSettingsScheme.parse(
    props.contentSettings
  );

  const settings = { ...contentSettings, ...displaySettings };

  const content = BoxArticleListContentScheme.parse(props.content);

  const { articles, pagination } = useFetchArticleList(
    content,
    settings,
    props.editMode
  );

  const articlesHeadingRef = useRef<HTMLDivElement>(null);

  return articles?.length === 0 && !props.editMode ? null : (
    <StyledBoxArticleList
      $settings={settings}
      className="article-list article-list-custom-style"
      ref={articlesHeadingRef}
    >
      {articles?.length !== 0 && (
        <StyledText
          $settings={settings.heading}
          className="article-list__heading"
        >
          {content.heading}
        </StyledText>
      )}
      {settings.article.type === ArticleListBoxType.SLIDER && (
        <BoxArticleListSlider
          settings={settings}
          content={content}
          editMode={props.editMode}
          articles={articles}
        />
      )}

      {settings.article.type === ArticleListBoxType.GRID && (
        <BoxArticleListGrid
          settings={settings}
          content={content}
          editMode={props.editMode}
          articles={articles}
          pagination={pagination}
          articlesHeadingRef={articlesHeadingRef}
        />
      )}
    </StyledBoxArticleList>
  );
};

export default Memo(withHydrationOnDemand({ on: ["visible"] })(BoxArticleList));
